export default {
  "container": "qYO",
  "content": "qYm",
  "logos": "qYp",
  "logo": "qYx",
  "animation": "qYw",
  "title": "qYN title-2 mb-32",
  "avatar": "qYv",
  "dropdown": "qYo",
  "disabled": "qYn",
  "organization": "qYi",
  "actions": "qYA"
};
