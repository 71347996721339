export default {
  "header": "qOx",
  "btn-preset": "qOw btn btn-filter mr-8 mb-8",
  "invoices-wrapper": "qON",
  "statements-table": "qOv",
  "no-result": "qOo",
  "lottie-illustration": "qOn",
  "wrapper": "qOi",
  "access-denied": "qOA",
  "access-denied-illustration": "qOY",
  "application-card": "qOh",
  "presets-wrapper": "qOf",
  "error-wrapper": "qOK",
  "error-container": "qOG",
  "error-illustration": "qOr mb-32",
  "invoicing-info": "qOb"
};
