export default {
  "create-invoice-button": "qKd btn btn--primary",
  "filters-container": "qKI",
  "header-actions": "qKt",
  "product-discovery": "qKM",
  "search-field": "qKP",
  "full-width": "qKl",
  "footer-import-cta": "qKX",
  "footer-show-existing-invoices-cta": "qKC"
};
