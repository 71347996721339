import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class SupplierInvoicesMatchTransactionRoute extends Route {
  @service router;

  beforeModel(params) {
    if (!params.to.queryParams.invoice_id) {
      this.router.transitionTo('supplier-invoices.list');
    }
  }
}
