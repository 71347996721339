import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { equal, gt } from 'macro-decorators';

import {
  CONTRACT_STATUS,
  FAQ_TRACKING_ORIGIN,
  PAY_LATER_COCKPIT_ROUTES,
  PAY_LATER_ELIGIBILITY_STATUSES,
} from 'qonto/constants/financing';
import { Insights } from 'qonto/react/financing/components/pay-later/cockpit/insights';
import { NotSignedState } from 'qonto/react/financing/components/pay-later/cockpit/not-signed-state';
import { PayLaterCockpitTabs } from 'qonto/react/financing/components/pay-later/cockpit/tabs';

export default class FinancingPayLaterController extends Controller {
  Tabs = PayLaterCockpitTabs;
  Insights = Insights;
  NotSignedState = NotSignedState;

  @service abilities;
  @service router;
  @service segment;

  queryParams = ['origin'];

  @equal('model.eligibility', PAY_LATER_ELIGIBILITY_STATUSES.ELIGIBLE) isEligible;
  @equal('model.eligibility', PAY_LATER_ELIGIBILITY_STATUSES.NON_ELIGIBLE) isNotEligible;
  @equal('model.eligibility', PAY_LATER_ELIGIBILITY_STATUSES.NO_MORE_ELIGIBLE) isNoMoreEligible;
  @equal('model.contractStatus', CONTRACT_STATUS.SIGNED) isContractSigned;
  @equal('model.contractStatus', CONTRACT_STATUS.ON_HOLD) isContractOnHold;
  @equal('model.contractStatus', CONTRACT_STATUS.NOT_SIGNED) isContractNotSigned;
  @gt('model.payLaterTransfersCount', 0) hasUsedPayLater;

  get isNotEligibleOrNoMoreEligible() {
    return this.isNotEligible || (this.isContractNotSigned && this.isNoMoreEligible);
  }

  get hasSignedContractOnce() {
    return this.isContractSigned || this.isContractOnHold;
  }

  get canFinanceTransfer() {
    return (
      this.abilities.can('create pay later transfer in financing') &&
      this.isContractSigned &&
      !this.isAvailableCreditAmountLessThanThreshold
    );
  }

  get canReactivate() {
    return this.isEligible && this.isContractOnHold;
  }

  get faqTrackingOrigin() {
    let { isContractOnHold, isNoMoreEligible } = this;

    let origin = FAQ_TRACKING_ORIGIN.ELIGIBLE;

    if (isContractOnHold) {
      origin = FAQ_TRACKING_ORIGIN.ON_HOLD;
    }

    if (isNoMoreEligible) {
      origin = FAQ_TRACKING_ORIGIN.NO_MORE_ELIGIBLE;
    }

    return origin;
  }

  get activeTab() {
    return this.router.currentRouteName === PAY_LATER_COCKPIT_ROUTES.IN_PROGRESS
      ? 'IN_PROGRESS'
      : 'COMPLETED';
  }

  get isAvailableCreditAmountLessThanThreshold() {
    let { availableCreditAmount, minTransferAmount } = this.model;

    return Number(availableCreditAmount?.value) < Number(minTransferAmount?.value);
  }

  @action
  handleTabChange(id) {
    switch (id) {
      case 'IN_PROGRESS':
        this.router.transitionTo(PAY_LATER_COCKPIT_ROUTES.IN_PROGRESS);
        this.segment.track('pay-later_cockpit_tab-inprogress-click');
        break;
      case 'COMPLETED':
        this.router.transitionTo(PAY_LATER_COCKPIT_ROUTES.COMPLETED);
        this.segment.track('pay-later_cockpit_tab-completed-click');
        break;
    }
  }
}
