export default {
  "dropdown-icon": "qWZ",
  "dropdown": "qWR",
  "download-button": "qWQ",
  "connections-banner": "qWe",
  "recommendation-cards": "qWB",
  "wrapper-with-cards": "qWE",
  "transactions-wrapper": "qWd",
  "separator": "qWI",
  "with-transaction": "qWt",
  "annual-billing-banner": "qWM",
  "mt-132": "qWP",
  "transactions-sidebar": "qWl",
  "empty-state-wrapper": "qWX",
  "wrapper-with-modular": "qWC",
  "modular-table-container": "qWk",
  "modular-table-sidebar": "qWJ",
  "sidebar-overlay": "qWs",
  "overlay-visible": "qWF"
};
