export default {
  "page-wrapper": "qwt",
  "container": "qwM",
  "spinner-container": "qwP",
  "left-panel": "qwl",
  "form": "qwX",
  "left-content": "qwC",
  "form-footer": "qwk",
  "delete-button": "qwJ",
  "header": "qws",
  "preview-container": "qwF",
  "email-preview": "qwg",
  "close-button": "qwD btn btn--icon-only btn--tertiary btn--large"
};
