export default {
  "page-header": "qWu",
  "header-breadcrumb": "quS",
  "tabs-container": "quc",
  "bottom-border": "quq",
  "tabs-separator": "quZ",
  "previous-request": "quR",
  "body-link": "quQ",
  "border-top": "que"
};
