export default {
  "container": "qnF",
  "caption": "qng caption",
  "compact-header": "qnD",
  "visible": "qnV",
  "page-container": "qnL",
  "main": "qnT",
  "tabs": "qnz",
  "tabs-nav": "qna",
  "tabs-panels": "qnH",
  "tabs-panel": "qnO body-2",
  "sidebar": "qnm",
  "cms-content": "qnp"
};
