export default {
  "page-wrapper": "qxp",
  "back-button": "qxx",
  "abort-button": "qxw",
  "container": "qxN",
  "subtitle": "qxv",
  "button": "qxo",
  "panel-list": "qxn",
  "panel-list-item": "qxi",
  "card-illustration": "qxA"
};
