export default {
  "body": "qAw",
  "members": "qAN",
  "members-results": "qAv",
  "members-list": "qAo",
  "members-title": "qAn caption-bold",
  "member": "qAi",
  "empty-illustration": "qAA",
  "empty-title": "qAY title-3",
  "empty-description": "qAh body-2",
  "invitable-members": "qAf",
  "details": "qAK",
  "pagination-footer": "qAG"
};
