import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class InvoicingSettingsController extends Controller {
  @service router;

  get isLoading() {
    return this.model.fetchInvoiceSubscriptionsStatsTask.isRunning;
  }

  get isManualNumberingDisabled() {
    let stats = this.model.fetchInvoiceSubscriptionsStatsTask?.lastSuccessful?.value.created;

    if (stats) {
      let { active, suspended, scheduled } = stats;
      return active + suspended + scheduled > 0;
    }

    return false;
  }

  @action handleClose() {
    let { queryParams } = this.router.currentRoute || {};
    let origin = queryParams?.origin || 'receivable-invoices.index';
    if (queryParams?.params) {
      this.router.transitionTo(origin, queryParams?.params);
    } else {
      this.router.transitionTo(origin);
    }
  }
}
