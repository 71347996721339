export default {
  "title": "qyz title-3",
  "personal-profile-container": "qya",
  "personal-profile-section": "qyH",
  "member-info-title": "qyO",
  "kyc-complete-profile": "qym",
  "info-fields": "qyp",
  "field": "qyx",
  "address-fields": "qyw",
  "field-span": "qyN",
  "input": "qyv",
  "cancel": "qyo"
};
