import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { getEmptyStateConfig } from 'qonto/constants/empty-states/upcoming-transactions';
import { UpcomingTransactionsTable } from 'qonto/react/components/cash-flow/components/upcoming-transactions-table';
import { ErrorState } from 'qonto/react/components/transactions/sidebar/details/attachments/error-state';

export default class UpcomingTransactionsIndexController extends Controller {
  @service intl;
  @service emptyStates;
  @service abilities;
  @tracked shouldShowEmptyState = false;

  upcomingTransactionsTable = UpcomingTransactionsTable;
  errorState = ErrorState;

  get emptyStateOptions() {
    let options = this.emptyStates.getEmptyStateOptions({
      isOrgEligibleForFeature: true,
      isEmptyGlobally: true,
      isEmptyLocally: true,
      hasActiveFilterOrSearch: false,
      config: getEmptyStateConfig(this.intl),
      abilities: {
        canView: this.abilities.canViewUpcomingTransactions,
      },
    });

    return options;
  }

  @action
  showEmptyState(isEmpty) {
    this.shouldShowEmptyState = isEmpty;
  }
}
