import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

export default class SupplierInvoicesIndexRoute extends Route {
  @service router;
  @service supplierInvoicesManager;

  async model() {
    let isFirstTimeUsage = await this.supplierInvoicesManager.fetchFirstTimeUsageTask.perform();

    return {
      isFirstTimeUsage,
    };
  }

  afterModel({ isFirstTimeUsage }) {
    if (variation('feature--boolean-improve-first-time-exp-si') && isFirstTimeUsage) {
      return this.router.replaceWith('supplier-invoices.intro');
    } else {
      return this.router.replaceWith('supplier-invoices.list');
    }
  }
}
