export default {
  "accounts": "qmJ",
  "header-subtitle": "qms title-4",
  "subtitle-container": "qmF",
  "subtitle": "qmg title-3 mb-16",
  "amount": "qmD body-2",
  "recommendations-section": "qmV",
  "dismissing": "qmL",
  "recommendations-divider": "qmT",
  "list--cashbeeSavings": "qmz",
  "connections-banner": "qma",
  "list": "qmH",
  "icon": "qmO",
  "badge": "qmm",
  "discover-tile": "qmp"
};
