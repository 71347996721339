export default {
  "container": "qKp",
  "form-container": "qKx",
  "title": "qKw",
  "title-border": "qKN",
  "tabs-container": "qKv",
  "preview-container": "qKo",
  "email-preview-container": "qKn",
  "close-button": "qKi btn btn--icon-only btn--tertiary btn--large"
};
