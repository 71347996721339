export default {
  "container": "qUX",
  "header-cell": "qUC caption-bold",
  "col-8": "qUk",
  "table": "qUJ",
  "cell": "qUs body-2",
  "cell-content": "qUF",
  "row": "qUg",
  "empty": "qUD",
  "quick-actions": "qUV",
  "card-infos": "qUL",
  "card-digits": "qUT",
  "empty-state": "qUz"
};
