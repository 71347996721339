export default {
  "container": "qvq",
  "page-wrapper": "qvZ",
  "header": "qvR",
  "back-button-wrapper": "qvQ",
  "header-inner": "qve",
  "header-main-wrapper": "qvB",
  "header-main": "qvE",
  "header-right": "qvd",
  "content-wrapper": "qvI",
  "content": "qvt",
  "content-body": "qvM",
  "sidebar": "qvP"
};
