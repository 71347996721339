/* eslint-disable @qonto/no-import-roles-constants */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';

import { Disclaimer } from '@repo/design-system-kit';

import { INVITATION_TYPES, ROLES } from 'qonto/constants/membership';

export default class GuestsController extends Controller {
  DisclaimerBlock = Disclaimer.Block;
  queryParams = ['teamId'];

  INVITATION_TYPES = INVITATION_TYPES;

  @service abilities;
  @service intl;
  @service organizationManager;
  @service segment;
  @service subscriptionManager;
  @service flowLinkManager;
  @service zendeskLocalization;
  @service toastFlashMessages;
  @service router;

  get activeGuests() {
    return this.model.guests.filter(guest => guest.active || guest.pending) ?? [];
  }

  get revokedGuests() {
    return this.model.guests.filter(guest => guest.revoked) ?? [];
  }

  get isManager() {
    return this.organizationManager.membership.role === ROLES.MANAGER;
  }

  get isHeaderInviteAccountantCTAShown() {
    return this.model.guests.length && this.isInviteAccountantCTAShown;
  }

  get isInviteAccountantCTAShown() {
    return !this.isManager && this.abilities.can('create invites');
  }

  get displayKycBanner() {
    return !this.isManager && (this.kycNotStarted || this.kycUnknown);
  }

  @action
  inviteAccountant() {
    let { kycSubmitted, kycAccepted, shouldSubmitKyc } = this.organizationManager.membership;
    let kycUnknown = kycSubmitted && !kycAccepted;

    if (shouldSubmitKyc) {
      return this.router.transitionTo('kyc.intro', {
        queryParams: {
          redirectRoute: 'members.active',
        },
      });
    }

    if (kycUnknown) {
      return this.toastFlashMessages.toastError(
        this.intl.t('user-access.kyc.error.pending-rejected-unknown')
      );
    }

    this.trackTeamInviteSelected('team_page');

    let stepId = this.subscriptionManager.hasReachedAccountantLimit
      ? 'plan-upgrade'
      : 'personal-info';

    this.flowLinkManager.transitionTo({
      name: 'member-invite',
      stepId,
      queryParams: { invitationType: INVITATION_TYPES.GUEST },
    });
  }

  get emptyStateOptions() {
    let options = {
      title: this.intl.t('accountant-access.empty-state.title'),
      subtitle: this.emptyStateSubtitle,
      lottieSrc: '/lotties/empty-state/no-team.json',
    };

    if (this.isInviteAccountantCTAShown) {
      options.button = {
        label: this.intl.t('btn.invite_an_accountant'),
        callback: this.inviteAccountant,
      };
    }

    return options;
  }

  get emptyStateSubtitle() {
    return this.isManager
      ? this.intl.t('accountant-access.manager-view.empty-state.subtitle')
      : this.intl.t('accountant-access.empty-state.subtitle', {
          link: htmlSafe(
            `<a
              href=${this.intl.t('accountant-access.empty-state.link', {
                faqUrl: this.zendeskLocalization.getLocalizedArticle(4359659),
              })}
              target="_blank"
              rel="noopener noreferrer"
              class="body-link"
              data-test-link
            >${this.intl.t('accountant-access.empty-state.subtitle-link')}</a>`
          ),
          htmlSafe: true,
        });
  }

  get kycUnknown() {
    let { kycSubmitted, kycAccepted } = this.organizationManager.membership;

    return kycSubmitted && !kycAccepted;
  }

  get kycNotStarted() {
    let { shouldSubmitKyc } = this.organizationManager.membership;

    return shouldSubmitKyc;
  }

  @action
  trackTeamInviteSelected(origin) {
    this.segment.track('team_invite_selected', {
      origin,
    });
  }
}
