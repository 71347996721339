import Controller, { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { Header } from 'qonto/react/components/receivable-invoices/header';

export default class ReceivableInvoicesNewIndexController extends Controller {
  @service router;
  @service store;
  @service segment;
  @service abilities;
  @service intl;

  @controller('receivable-invoices.new') parentController;

  @reads('parentController.logo') logo;

  @reads('parentController.saveTask') saveTask;
  @reads('parentController.saveAsDraftTask') saveAsDraftTask;
  @reads('parentController.onCloseTask') onCloseTask;

  header = Header;

  get breadcrumb() {
    let items = [
      {
        title: this.intl.t(
          'receivable-invoices.invoice-creation.navigation.breadcrumb.invoice-details'
        ),
        active: true,
      },
      {
        title: this.intl.t(
          'receivable-invoices.invoice-creation.navigation.breadcrumb.products-and-services'
        ),
        disabled: true,
      },
    ];

    if (this.abilities.can('write paymentLink')) {
      items.push({
        title: this.intl.t(
          'receivable-invoices.invoice-creation.navigation.breadcrumb.payment-details'
        ),
        disabled: true,
      });
    }

    return items;
  }

  continueTask = dropTask(async () => {
    try {
      await this.model.invoice.validate();
      await this.router.transitionTo('receivable-invoices.new.payment');
      this.segment.track('invoice-creation_continue_clicked');
    } catch (error) {
      this.parentController.handleError(error);
    }
  });

  @action
  handleOpenSettings() {
    this.segment.track('invoicing_settings_open', { origin: 'invoiceForm' });
    this.router.transitionTo('invoicing-settings', {
      queryParams: {
        origin: 'receivable-invoices.new',
      },
    });
  }
}
