import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class ClientsEditRoute extends Route {
  @service abilities;
  @service router;
  @service menu;
  @service modals;
  @service organizationManager;
  @service store;

  activate() {
    this.menu.hide();
  }

  deactivate() {
    this.menu.show();
  }

  beforeModel() {
    if (!this.abilities.can('write client-hubs')) {
      return this.router.replaceWith('clients');
    }
  }

  async model() {
    let settings;

    let { client } = this.modelFor('clients.client');

    if (variation('feature--boolean-ar-advanced-customization')) {
      settings = await this.fetchSettingsTask.perform().catch(ignoreCancelation);
    }

    return {
      client,
      settings,
    };
  }

  afterModel({ client, settings }, transition) {
    let { redirect, redirectId } = transition.to.queryParams;

    let onSaveRedirect = () => {
      if (redirect && redirectId) {
        this.router.transitionTo(redirect, redirectId);
      }
    };

    this.modal = this.modals.open(
      'receivable-invoices/client-form',
      {
        client,
        isEdit: true,
        onSaveRedirect,
        isFullScreenModal: true,
        showDeleteButton: !this.isFromQuote,
        savedAddressName: settings?.clientAddressName,
      },
      {
        focusTrapOptions: {
          initialFocus: false,
          onDeactivate: this.onCloseModal,
        },
      }
    );
  }

  @action
  willTransition(transition) {
    if (!transition.targetName.includes('clients.client.edit')) {
      let { client } = this.context;
      client.rollbackAttributes();
    }
  }

  resetController(_, isExiting) {
    super.resetController(...arguments);
    if (isExiting) {
      this.modal.close();
    }
  }

  @action onCloseModal() {
    this.router.transitionTo('clients');
  }

  fetchSettingsTask = dropTask(async () => {
    let organizationId = this.organizationManager.organization.id;
    let settings = await this.store.findRecord('receivable-invoices-settings', organizationId);

    return settings;
  });
}
