export default {
  "member-details": "qvr",
  "header": "qvb",
  "header-img": "qvy",
  "invited-persona": "qvU",
  "revoked-persona": "qvj",
  "invited-icon": "qvW",
  "revoked-icon": "qvu",
  "dropdown": "qoS",
  "header-body": "qoc",
  "name": "qoq title-3",
  "email": "qoZ caption-bold",
  "actions": "qoR body-2",
  "actions-cards": "qoQ",
  "actions-transactions": "qoe",
  "body": "qoB",
  "expense-permissions-section": "qoE",
  "body-title": "qod title-4",
  "body-attr": "qoI",
  "body-label": "qot",
  "attr-buttons": "qoM",
  "body-role": "qoP small",
  "details-actions": "qol",
  "actions-item": "qoX",
  "disclaimer": "qoC",
  "permissions": "qok",
  "permission": "qoJ",
  "permission-label": "qos",
  "permission-check": "qoF",
  "permission-missing": "qog",
  "editable-section": "qoD"
};
