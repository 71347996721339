export default {
  "supplier-invoices-page-header": "qjl",
  "filters-container": "qjX",
  "search-bar": "qjC",
  "filters": "qjk",
  "bulk-actions": "qjJ",
  "item-count": "qjs",
  "higher-index": "qjF",
  "title-wrapper": "qjg",
  "title": "qjD",
  "subtitle": "qjV",
  "table-container": "qjL",
  "file-dropzone": "qjT",
  "dropzone-visible": "qjz",
  "header-actions": "qja",
  "header-wrapper": "qjH",
  "disclaimer-wrapper": "qjO"
};
