export default {
  "vat-number": "qyR body-2",
  "banner": "qyQ",
  "container": "qye",
  "organization-profile": "qyB",
  "input": "qyE",
  "medium-input": "qyd medium",
  "small-input": "qyI small",
  "actions": "qyt",
  "company-info": "qyM",
  "company-info-section": "qyP",
  "company-address-section": "qyl",
  "company-country": "qyX",
  "company-billing-email": "qyC",
  "company-address": "qyk"
};
