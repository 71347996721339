import Controller, { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { reads } from 'macro-decorators';

import { Header } from 'qonto/react/components/receivable-invoices/header';

export default class QuotesNewIndexController extends Controller {
  @service router;
  @service segment;
  @service abilities;
  @service intl;

  @controller('quotes.new') parentController;

  @reads('parentController.logo') logo;

  @reads('parentController.saveQuoteTask') saveQuoteTask;
  @reads('parentController.closeQuoteForm') closeQuoteForm;

  header = Header;

  get breadcrumb() {
    let items = [
      {
        title: this.intl.t(
          'receivable-invoices.quote-creation.navigation.breadcrumb.quote-details'
        ),
        active: true,
      },
      {
        title: this.intl.t(
          'receivable-invoices.invoice-creation.navigation.breadcrumb.products-and-services'
        ),
        disabled: true,
      },
    ];

    return items;
  }

  @action
  handleOpenSettings() {
    this.segment.track('invoicing_settings_open', { origin: 'quoteForm' });
    this.router.transitionTo('invoicing-settings', {
      queryParams: {
        origin: 'quotes.new',
      },
    });
  }
}
