import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { getDefaultClientValues } from 'qonto/models/client-hub';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class ClientsNewRoute extends Route {
  @service abilities;
  @service router;
  @service menu;
  @service store;
  @service modals;
  @service organizationManager;

  activate() {
    this.menu.hide();
  }

  deactivate() {
    this.menu.show();
  }

  beforeModel() {
    if (!this.abilities.can('write client-hubs')) {
      return this.router.replaceWith('clients');
    }
  }

  async model() {
    let settings;

    let client = this.store.createRecord('client-hub', getDefaultClientValues(this));

    if (variation('feature--boolean-ar-advanced-customization')) {
      settings = await this.fetchSettingsTask.perform().catch(ignoreCancelation);
    }

    return {
      client,
      settings,
    };
  }

  afterModel({ client, settings }) {
    this.modal = this.modals.open(
      'receivable-invoices/client-form',
      { client, isFullScreenModal: true, savedAddressName: settings?.clientAddressName },
      {
        focusTrapOptions: {
          initialFocus: false,
          onDeactivate: this.onCloseModal,
        },
      }
    );
  }

  resetController(_, isExiting) {
    super.resetController(...arguments);
    if (isExiting) {
      this.modal.close();
    }
  }

  @action onCloseModal() {
    this.router.transitionTo('clients');
  }

  fetchSettingsTask = dropTask(async () => {
    let organizationId = this.organizationManager.organization.id;
    let settings = await this.store.findRecord('receivable-invoices-settings', organizationId);

    return settings;
  });
}
