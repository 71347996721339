export default {
  "page": "qfK",
  "container": "qfG",
  "form-container": "qfr",
  "preview-container": "qfb",
  "pdf-preview-container": "qfy",
  "content": "qfU",
  "title": "qfj",
  "tabs-container": "qfW",
  "close-button": "qfu btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qKS",
  "header": "qKc",
  "without-tabs": "qKq"
};
