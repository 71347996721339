export default {
  "multi-transfer": "qWa",
  "container": "qWH",
  "title": "qWO",
  "subtitle": "qWm",
  "form-wrapper": "qWp",
  "form": "qWx",
  "form-title": "qWw",
  "files-buttons": "qWN",
  "files-buttons-item": "qWv",
  "selected": "qWo"
};
