export default {
  "container": "qbk",
  "content": "qbJ",
  "setup-box": "qbs",
  "setup-wizard": "qbF",
  "setup-success": "qbg",
  "setup-success-lottie": "qbD",
  "setup-close-btn": "qbV",
  "tray-connection": "qbL",
  "close-button": "qbT"
};
