export default {
  "container": "qfM",
  "form-container": "qfP",
  "preview-container": "qfl",
  "credit-note-preview-container": "qfX",
  "credit-note-preview": "qfC",
  "content": "qfk",
  "title": "qfJ",
  "tabs-container": "qfs",
  "close-button": "qfF btn btn--icon-only btn--tertiary btn--large",
  "header": "qfg",
  "without-tabs": "qfD"
};
