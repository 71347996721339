/* eslint-disable require-await */
import { assert } from '@ember/debug';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class OrganizationsShowRoute extends Route {
  @service abilities;
  @service cardsManager;
  @service topBanners;
  @service bannerFlashMessages;
  @service toastFlashMessages;
  @service flowLinkManager;
  @service notifierCounterManager;
  @service notifierManager;
  @service organizationManager;
  @service refiner;
  @service segment;
  @service store;
  @service subscriptionManager;
  @service userManager;
  @service payment;
  @service modals;
  @service internationalOutManager;
  @service sentry;

  afterModel(organization, transition) {
    super.afterModel(...arguments);

    let {
      currentPricePlan,
      currentSubscription,
      activeFreeTrialProducts,
      productsAvailableForTrial,
    } = this.subscriptionManager;

    let { membership } = this.organizationManager;

    if (membership) {
      this.segment.identify({
        distinctId: membership.get('id'),
        legalCountry: this.organizationManager.organization.legalCountry,
        pricePlan: currentPricePlan?.code,
        role: membership.role,
        referral_code: organization.referralCode,
        referee_code_used: organization.referrer?.referralCode,
        permissions: membership.permissions,
        'fees_upsell_A/B': this.organizationManager.organization.hasFeeUpsell,
        initialTrialCurrent: currentSubscription?.activeTrial?.trial_type === 'initial',
        freeTrialCurrent: activeFreeTrialProducts?.length ? activeFreeTrialProducts : 'none',
        freeTrialEligibility: productsAvailableForTrial?.length
          ? productsAvailableForTrial
          : 'none',
      });
      this.segment.group({ groupId: organization.get('id') });
    }

    if (
      !organization.get('underRegistration') &&
      transition.from?.name !== 'onboarding.topup.success'
    ) {
      this.bannerFlashMessages.clearTopBannerMessages();
      this.toastFlashMessages.clearToastMessages();
    }

    // Asynchronously load Refiner widget
    // Disabling the rule since the following task is already handling errors
    // eslint-disable-next-line ember-concurrency/no-perform-without-catch
    this.refiner.loadTask.perform();
  }

  async setupController(controller, model) {
    super.setupController(controller, model);
    this.notifierCounterManager.subscribe();
    this.cardsManager.fetchCounters();
  }

  activate() {
    this.notifierManager.on('bank_account.synced', this, '_handleBankAccountSync');
    this.notifierManager.on(
      'organization.contract_signed_registered',
      this.organizationManager,
      'updateBankAccounts'
    );
    this.notifierManager.on('kyc.updated', this, this._kycUpdated);
  }

  deactivate() {
    this.notifierManager.off('bank_account.synced', this, '_handleBankAccountSync');
    this.notifierManager.off(
      'organization.contract_signed_registered',
      this.organizationManager,
      'updateBankAccounts'
    );
    this.notifierManager.off('kyc.updated', this, this._kycUpdated);
  }

  redirect(organization, transition) {
    if (transition.isAborted) {
      return;
    }

    if (organization.underRegistration) {
      return this.transitionTo('capital', organization);
    }

    let { membership } = this.organizationManager;
    let isFromSettings = /^settings\./.test(transition.from?.name);

    if (!membership) {
      let orgId = this.organizationManager.organization.id;
      let userId = this.userManager.currentUser.id;
      let message = `membership is undefined for organization ${orgId} and user ${userId}`;
      assert(message, membership);
      Sentry.captureMessage(message);
    } else if (
      !isFromSettings &&
      !membership.onboardedCard &&
      this.abilities.can('view card onboarding') &&
      !this.payment.onboardingCardSkipped
    ) {
      return this.flowLinkManager.transitionTo({
        name: 'card-onboarding',
        stepId: 'choose-card',
      });
    }
  }

  serialize(model) {
    // in some cases `model` can be undefined. in these cases we fall back to
    // the current route model to get the current organization slug.
    return { organization_slug: (model || this.currentModel).get('slug') };
  }

  _handleBankAccountSync(payload) {
    if (payload.object_type === 'bank_account' && payload.object && payload.object.id) {
      this.store.pushPayload({ bank_account: payload.object });
    } else {
      Sentry.withScope(() => {
        Sentry.setExtra('object_type', payload.object_type);
        Sentry.setExtra('object_id', payload.object_id);
        this.sentry.captureException(new Error('Unexpected `bank_account.synced` event received'));
      });
    }
  }

  /**
   * Banners must be reloaded manually as they have a dependency with kycStatus.
   * We do not care about the payload data (kycStatus & kycSubmitedAt) because
   * when we load membership again it will already come with that information up-to-date
   */
  async _kycUpdated(payload) {
    if (payload.object_type === 'kyc') {
      await this.organizationManager.loadOrganizationAndMemberships();
      this.bannerFlashMessages.clearTopBannerMessages();
      await this.topBanners.triggerBanners();
    }
  }
}
