export default {
  "container": "qrD",
  "wrapper": "qrV",
  "grey-header-block": "qrL",
  "connect-app-header": "qrT",
  "connect-app-header-link": "qrz",
  "connect-app-header-content": "qra",
  "connect-app-header-content-default": "qrH",
  "connect-app-header-content-left": "qrO",
  "button-block": "qrm",
  "connect-app-body": "qrp",
  "connect-app-body-left-content": "qrx",
  "connect-app-body-sidebar": "qrw"
};
