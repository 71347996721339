export default {
  "guests": "qoV",
  "guests--upsell-screen": "qoL",
  "no-members": "qoT",
  "teams-header": "qoz",
  "empty": "qoa",
  "invite-text": "qoH",
  "body": "qoO",
  "members": "qom",
  "members-list": "qop",
  "members-title": "qox caption-bold",
  "member": "qow",
  "details": "qoN",
  "kyc-disclaimer": "qov",
  "kyc-disclaimer-width": "qoo"
};
