export default {
  "page": "qfO",
  "container": "qfm",
  "form-container": "qfp",
  "preview-container": "qfx",
  "pdf-preview-container": "qfw",
  "content": "qfN",
  "title": "qfv",
  "tabs-container": "qfo",
  "close-button": "qfn btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qfi",
  "header": "qfA",
  "without-tabs": "qfY"
};
