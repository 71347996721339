export default {
  "page": "qhy",
  "container": "qhU",
  "form-container": "qhj",
  "preview-container": "qhW",
  "pdf-preview-container": "qhu",
  "content": "qfS",
  "header": "qfc",
  "close-button": "qfq btn btn--icon-only btn--tertiary btn--large"
};
