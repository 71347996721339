export default {
  "container": "qYF",
  "content": "qYg",
  "logos": "qYD",
  "logo": "qYV",
  "animation": "qYL",
  "title": "qYT title-2 mb-32",
  "avatar": "qYz",
  "actions": "qYa mb-32",
  "footer": "qYH body-2"
};
