export default {
  "page-wrapper": "qOy",
  "container": "qOU",
  "sidebar": "qOj",
  "search-input": "qOW",
  "organizations-list": "qOu",
  "content": "qmS",
  "no-result": "qmc",
  "lottie-illustration": "qmq",
  "accounting-header": "qmZ",
  "logo": "qmR",
  "accounting-title": "qmQ"
};
