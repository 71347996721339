export default {
  "container": "qyi",
  "security-method": "qyA",
  "icon": "qyY",
  "body": "qyh",
  "status": "qyf body-2",
  "device": "qyK",
  "device-icon": "qyG",
  "subtitle": "qyr body-2",
  "current": "qyb"
};
