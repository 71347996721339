export default {
  "page": "qhn",
  "container": "qhi",
  "form-container": "qhA",
  "preview-container": "qhY",
  "pdf-preview-container": "qhh",
  "content": "qhf",
  "header": "qhK",
  "close-button": "qhG btn btn--icon-only btn--tertiary btn--large"
};
