export default {
  "bank-accounts": "qGL l-app-content__wrapper",
  "header-wrapper": "qGT",
  "scrolled-top": "qGz",
  "header": "qGa l-app-content__page-header",
  "create-account-button": "qGH btn btn--primary",
  "main": "qGO",
  "accounts": "qGm",
  "subtitle-container": "qGp",
  "subtitle": "qGx title-3 mb-16",
  "amount": "qGw body-2",
  "list--cashbeeSavings": "qGN"
};
