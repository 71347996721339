import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';

import { PopupDestructive } from 'qonto/react/components/popups/destructive';
import { FlowSetup } from 'qonto/routes/flows/setup/internals';

class PaymentLinksOnboardingFlowDataContext {
  @tracked userBankAccountId;
  @tracked userPhoneNumber;
  @tracked userWebsiteUrl;
  @tracked tosAccepted = false;
  @tracked isNotConnected = true;
  @tracked origin;
  @tracked draftInvoiceId;

  constructor({ userBankAccountId, userPhoneNumber, userWebsiteUrl, origin, draftInvoiceId } = {}) {
    this.userBankAccountId = userBankAccountId;
    this.userPhoneNumber = userPhoneNumber;
    this.userWebsiteUrl = userWebsiteUrl;
    this.origin = origin;
    this.draftInvoiceId = draftInvoiceId;
  }
}

export default class PaymentLinksOnboardingFlowSetup extends FlowSetup {
  @service abilities;
  @service router;
  @service homePage;
  @service organizationManager;
  @service mollie;
  @service flow;
  @service sentry;
  @service reactPopup;
  @service intl;
  @service segment;

  initialStepId = 'intro';

  constructor() {
    super(...arguments);
    this.dataContext = new PaymentLinksOnboardingFlowDataContext({
      userBankAccountId: this.initialBankAccountId,
      origin: this.router.currentRoute.queryParams.origin,
      draftInvoiceId: this.router.currentRoute.queryParams.draftInvoiceId,
    });
  }

  get initialBankAccountId() {
    return this.organizationManager.organization.mainAccount.id;
  }

  async beforeFlow({ stepId }) {
    Sentry.getCurrentScope().setTag('CFT', 'cards');

    if (this.abilities.cannot('write paymentLink')) {
      this.transitionBack();
    }

    try {
      await this.mollie.fetchConnectionTask.perform();
    } catch (error) {
      if (stepId === 'intro') {
        // fallback to the default error handling
        throw error;
      } else {
        // let the feedback step handle the error
        this.sentry.captureException(error);
      }
    }
    if (!this.mollie.isNotConnected) {
      this.dataContext.isNotConnected = false;
      next(() => this.flow.next());
    }
  }

  @action
  onComplete() {
    this.transitionBack();
  }

  shouldDisplayAbortModal(stepId, dataContext) {
    return (
      stepId === 'form' &&
      (dataContext.userPhoneNumber ||
        dataContext.userWebsiteUrl ||
        dataContext.tosAccepted ||
        dataContext.userBankAccountId !== this.initialBankAccountId)
    );
  }

  onAbortTask = dropTask(async (dataContext, { id: stepId }) => {
    if (this.shouldDisplayAbortModal(stepId, dataContext)) {
      let result = await this.openAbortModalTask.perform();
      if (result === 'confirm') {
        this.segment.track('payment-link_activation_canceled');
        this.transitionBack();
        return true;
      }
      return false;
    }

    this.transitionBack();
    return false;
  });

  openAbortModalTask = dropTask(
    async () =>
      await this.reactPopup.open(PopupDestructive, {
        title: this.intl.t('payment-links.onboarding.modal.title'),
        subtitle: this.intl.t('flow-in-flow.modal.abort.subtitle'),
        cancelText: this.intl.t('btn.cancel'),
        confirmText: this.intl.t('btn.leave'),
      })
  );

  transitionBack() {
    if (this.dataContext.origin) {
      if (this.dataContext.draftInvoiceId) {
        this.router.transitionTo(this.dataContext.origin, this.dataContext.draftInvoiceId);
      } else {
        this.router.transitionTo(this.dataContext.origin);
      }
    } else {
      this.homePage.replaceWithDefaultPage();
    }
  }
}
