import Controller from '@ember/controller';
import { later } from '@ember/runloop';
import { service } from '@ember/service';

import { isTesting, macroCondition } from '@embroider/macros';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import {
  ERROR_TOAST_IGNORE_HTTP_STATUSES,
  SENTRY_IGNORE_HTTP_STATUSES,
} from 'qonto/constants/receivable-invoice';
import { ErrorInfo } from 'qonto/utils/error-info';
import scrollIntoView from 'qonto/utils/scroll-into-view';

export default class QuotesEditController extends Controller {
  @service router;
  @service modals;
  @service intl;
  @service organizationManager;
  @service toastFlashMessages;
  @service segment;
  @service sentry;

  @reads('organizationManager.organization') organization;

  get logo() {
    return this.organizationManager.organization.get('isDefaultAvatar') === false
      ? this.organizationManager.organization.picture
      : null;
  }

  get editQuoteOrigin() {
    let { currentRoute } = this.router;
    let { parent } = currentRoute;
    return { origin: currentRoute.name, params: parent?.params?.id };
  }

  closeQuoteForm = dropTask(async () => {
    await this.modals.open(
      'receivable-invoices/cancel-invoice-form-modal',
      {
        title: this.intl.t('receivable-invoices.invoice-modal.edit-quote-modal.title'),
        description: this.intl.t('receivable-invoices.invoice-modal.edit-quote-modal.description'),
        closeWithoutSaving: this.intl.t(
          'receivable-invoices.invoice-modal.edit-quote-modal.return'
        ),
        saveAsDraft: this.intl.t('receivable-invoices.invoice-modal.edit-quote-modal.confirm'),
        closeWithoutSavingTask: this.modalConfirmCancelTask,
        saveAsDraftTask: this.modalSaveAsDraftTask,
      },
      {
        className: 'epm-popup-modal',
      }
    );
  });

  modalConfirmCancelTask = dropTask(async closeModal => {
    this.model.quote.rollbackAttributes();
    this.model.quote.items.forEach(item => item.rollbackAttributes());
    this.router.transitionTo('quotes.index', {
      queryParams: {
        status: 'pending_approval',
      },
    });
    this.segment.track('quote-editing_cancel_confirmed');
    await closeModal();
  });

  modalSaveAsDraftTask = dropTask(async closeModal => {
    await this.updateQuoteTask.perform();
    await closeModal();
  });

  updateQuoteTask = dropTask(async () => {
    try {
      await this.model.quote.updateQuote();
      this.model.quote.clearItemsWithNoId();
      this.toastFlashMessages.toastSuccess(
        this.intl.t('receivable-invoices.edit-quote-modal.success')
      );
      this.router.transitionTo('quotes.share', this.model.quote.id);
      this.segment.track('quote-editing_update_confirmed');
    } catch (error) {
      this.handleError(error);
    }
  });

  handleError(error) {
    let errorInfo = ErrorInfo.for(error);
    if (errorInfo.shouldSendToSentry && !SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)) {
      this.sentry.captureException(error);
    }
    if (!ERROR_TOAST_IGNORE_HTTP_STATUSES.includes(error.status)) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
    later(this, this.scrollIntoError, macroCondition(isTesting()) ? 0 : 200);
  }

  scrollIntoError() {
    scrollIntoView('[data-has-error]');
  }
}
