import Controller from '@ember/controller';
import { action } from '@ember/object';
import { schedule } from '@ember/runloop';
import { service } from '@ember/service';
import { cached, tracked } from '@glimmer/tracking';

import formatFileSize from '@qonto/ui-kit/utils/format-bytes';
import { Disclaimer, TextAreaField } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { companyTypes, GBR_LEGAL_CODE } from 'qonto/constants/company-types';
import TEMPLATE_VALUES from 'qonto/constants/env-helper';
import { DE_FREELANCERS_LEGAL_CODES } from 'qonto/constants/legal-codes';
import { ErrorInfo } from 'qonto/utils/error-info';
import scrollIntoView from 'qonto/utils/scroll-into-view';

const NATURE_OF_OPERATIONS_OPTIONS = {
  CORE_BANKING: 'core_banking',
  INVOICING: 'invoicing',
  SPEND_MANAGEMENT: 'spend_management',
  BOOK_KEEPING: 'book_keeping',
};

export default class CompanyEditController extends Controller {
  disclaimerInline = Disclaimer.Inline;
  disclaimerBlock = Disclaimer.Block;
  textAreaField = TextAreaField;

  @service activityType;
  @service toastFlashMessages;
  @service intl;
  @service modals;
  @service router;
  @service segment;
  @service sentry;
  @service zendeskLocalization;

  @tracked showEmptyFileError = false;
  @tracked showValidations = false;

  previewModal;

  @tracked natureOfOpsOptions = [
    {
      key: NATURE_OF_OPERATIONS_OPTIONS.CORE_BANKING,
      value: this.intl.t(
        'organizations.profile.company_profile.organization_edit.additional_information.nature_of_operations.option1'
      ),
    },
    {
      key: NATURE_OF_OPERATIONS_OPTIONS.INVOICING,
      value: this.intl.t(
        'organizations.profile.company_profile.organization_edit.additional_information.nature_of_operations.option2'
      ),
    },
    {
      key: NATURE_OF_OPERATIONS_OPTIONS.SPEND_MANAGEMENT,
      value: this.intl.t(
        'organizations.profile.company_profile.organization_edit.additional_information.nature_of_operations.option3'
      ),
    },
    {
      key: NATURE_OF_OPERATIONS_OPTIONS.BOOK_KEEPING,
      value: this.intl.t(
        'organizations.profile.company_profile.organization_edit.additional_information.nature_of_operations.option4'
      ),
    },
  ];

  @tracked natureOfOps = this.model.organizationChangeRequest.natureOfOperations;

  get selectedNatureOfOpsOptions() {
    if (!this.natureOfOps) return [];

    return this.natureOfOpsOptions?.filter(option => this.natureOfOps.includes(option.key));
  }

  get formattedMaxSize() {
    return formatFileSize(this.intl, TEMPLATE_VALUES.avatarMaxSize);
  }

  @cached
  get activityTypeOptions() {
    return Object.entries(this.activityType.activityCodes).map(([key, value]) => ({
      legalSector: key,
      title: `${key}. ${value}`,
    }));
  }

  get companyLegalForms() {
    let { organization } = this.model;
    return companyTypes[`${organization.legalCountry}`];
  }

  get selectedCompanyType() {
    let { legalCode } = this.model.organizationChangeRequest;
    let { legalCountry } = this.model.organization;

    if (legalCode && legalCountry) {
      try {
        let companyTypeOption = this.intl.t(
          `organizations.profile.company_profile.company_type_option.${legalCountry}.${legalCode}`
        );

        return companyTypeOption;
      } catch (error) {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      }
    }

    return '';
  }

  get selectedActivityType() {
    let { legalSector } = this.model.organizationChangeRequest;
    if (legalSector) {
      let activityTypeDescription = this.activityType.activityCodes[legalSector];
      return {
        legalSector,
        title: `${legalSector}. ${
          activityTypeDescription ||
          this.intl.t(
            'organizations.profile.company_profile.organization_edit.activity_type_unknown_code'
          )
        }`,
      };
    }
    return null;
  }

  get uploaderLabel() {
    return this.intl.t('organizations.profile.company_profile.organization_edit.upload_kbis');
  }

  get isDeFreelancer() {
    let { organizationChangeRequest } = this.model;
    return DE_FREELANCERS_LEGAL_CODES.includes(organizationChangeRequest.legalCode);
  }

  get isGbR() {
    let { organizationChangeRequest } = this.model;
    return organizationChangeRequest.legalCode === GBR_LEGAL_CODE;
  }

  get isDE() {
    let { organization } = this.model;
    return organization.legalCountry === 'DE';
  }

  get showEstimatedAnnualTurnoverField() {
    let { organization } = this.model;
    return organization.isItalian;
  }

  get isActivityDescriptionMandatory() {
    let { organization } = this.model;
    return organization.isItalian;
  }

  get activityDescriptionErrorMessage() {
    let { organizationChangeRequest } = this.model;

    if (
      this.isActivityDescriptionMandatory &&
      organizationChangeRequest.validations.attrs.activityDescription.message &&
      this.showValidations
    ) {
      return organizationChangeRequest.validations.attrs.activityDescription.message;
    }
  }

  get hasAnnualTurnoverErrorMessage() {
    return (
      this.showValidations &&
      this.model.organizationChangeRequest.validations.attrs.annualTurnover.message
    );
  }

  get hasEstimatedAnnualTurnoverErrorMessage() {
    return (
      this.showValidations &&
      this.model.organizationChangeRequest.validations.attrs.estimatedAnnualTurnover.message
    );
  }

  get hasYearlyTransactionVolumeErrorMessage() {
    return (
      this.showValidations &&
      this.model.organizationChangeRequest.validations.attrs.yearlyTransactionVolume.message
    );
  }

  get hasMaxSinglePaymentAmountErrorMessage() {
    return (
      this.showValidations &&
      this.model.organizationChangeRequest.validations.attrs.maxSinglePaymentAmount.message
    );
  }

  get hasNatureOfOperationsErrorMessage() {
    return (
      this.showValidations &&
      this.model.organizationChangeRequest.validations.attrs.natureOfOperations.message
    );
  }

  get activityDescriptionTitle() {
    let { organization } = this.model;

    if (organization.isItalian) {
      return this.intl.t(
        'organizations.profile.company_profile.organization_edit.activity_description_mandatory.title'
      );
    }
    return this.intl.t(
      'organizations.profile.company_profile.organization_edit.activity_description'
    );
  }

  get activityDescriptionPlaceholder() {
    let { organization } = this.model;

    return this.intl.t(
      'organizations.profile.company_profile.organization_edit.activity_description_placeholder',
      { organizationName: organization.legalName || organization.name }
    );
  }

  @action
  handleFileUploaded(file) {
    this.showEmptyFileError = false;
    this.model.kbis.push(file);
  }

  @action
  handleDeleteFile(file) {
    file.deleteRecord();
    let index = this.model.kbis.indexOf(file);
    this.model.kbis.splice(index, 1);
    this.previewModal?.close();
  }

  @action
  handlePreviewFile(file) {
    this.previewModal = this.modals.open('file-preview-modal', {
      isFullScreenModal: true,
      selectedFile: file,
      deletable: false,
    });
  }

  @action handleDropAvatar() {
    this.set('model.organization.avatar', null);
    this.set('model.organization.isDefaultAvatar', true);
    this.model.organization.save();
    this.segment.track('company_logo_delete', { origin: 'company_info' });
  }

  @action handleAddAvatar(avatar) {
    this.modals.open('avatar-confirm-dialog', {
      isFullScreenModal: false,
      avatarCandidate: avatar,
      hideCropper: true,
      confirmTask: this.handleAvatarModalConfirmTask,
      cancelEvent: this.handleAvatarModalCancel,
    });
    this.segment.track('company_logo_upload_clicked', { origin: 'company_info' });
  }

  @action handleAvatarModalCancel() {
    this.segment.track('company_logo_upload_cancel', { origin: 'company_info' });
  }

  @action handleChangeTaxCountry(taxCountry) {
    let { organizationChangeRequest, organization } = this.model;

    // If a different taxCountry than the current one is selected,
    // then we clear the field
    if (organizationChangeRequest.taxCountry !== taxCountry) {
      organizationChangeRequest.taxIdentificationNumber = '';
    }

    // If Tax Country already exists on the original Organization,
    // then we use the Tax Identification Number associated to this country
    if (organization.taxResidenceCountry === taxCountry) {
      organizationChangeRequest.taxIdentificationNumber =
        organization.taxResidenceTaxIdentificationNumber;
    }

    // If the selected taxCountry is FR and the Organization legalCountry is also FR,
    // then we replace the taxIdentificationNumber by the SIREN/SIRET
    if (organization.legalCountry === 'FR' && taxCountry === 'FR') {
      organizationChangeRequest.taxIdentificationNumber = organization.legalNumber;
    }

    organizationChangeRequest.taxCountry = taxCountry;
  }

  @action handleSelectActivityType({ legalSector }) {
    this.model.organizationChangeRequest.legalSector = legalSector;
  }

  handleAvatarModalConfirmTask = dropTask(async (close, file) => {
    this.model.organization.set('avatar', file);
    this.model.organization.set('isDefaultAvatar', false);
    try {
      await this.model.organization.save();
      this.toastFlashMessages.toastSuccess(this.intl.t('toasts.settings_updated'));
      this.segment.track('company_logo_upload_success', { origin: 'company_info' });
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
    } finally {
      close();
    }
  });

  @action
  onClose() {
    let { organizationChangeRequest, organization } = this.model;

    if (organizationChangeRequest.get('isNew')) {
      organizationChangeRequest.activityDescription = organization.activityDescription;
      organizationChangeRequest.addressCity = organization.address.city;
      organizationChangeRequest.addressCountry = organization.address.country;
      organizationChangeRequest.addressFirstLine = organization.address.firstLine;
      organizationChangeRequest.addressSecondLine = organization.address.secondLine;
      organizationChangeRequest.addressThirdLine = organization.address.thirdLine;
      organizationChangeRequest.addressZipCode = organization.address.zipcode;
      organizationChangeRequest.contactEmail = organization.contactEmail;
      organizationChangeRequest.estimatedYearlyIncome = organization.estimatedYearlyIncome;
      organizationChangeRequest.euVatNumber = organization.euVatNumber;
      organizationChangeRequest.legalCode = organization.legalCode;
      organizationChangeRequest.legalName = organization.legalName;
      organizationChangeRequest.legalNumber = organization.legalNumber;
      organizationChangeRequest.legalSector = organization.legalSector;
      organizationChangeRequest.name = organization.tradeName;
      organizationChangeRequest.taxCountry = organization.taxResidenceCountry;
      organizationChangeRequest.taxIdentificationNumber =
        organization.taxResidenceTaxIdentificationNumber;
      organizationChangeRequest.websiteUrl = organization.websiteUrl;
      organizationChangeRequest.legalRegistrationDate = organization.legalRegistrationDate;
    } else {
      organizationChangeRequest.rollbackAttributes();
    }

    this.router.transitionTo('settings.company-profile.index');
  }

  @action
  handleNatureOfOpsUpdate(selected) {
    this.natureOfOps = Array.isArray(selected) ? selected.map(op => op.key) : null;
    this.model.organizationChangeRequest.natureOfOperations = this.natureOfOps;
  }

  scrollToFirstErrorInput() {
    schedule('afterRender', () => scrollIntoView('[data-has-error]', { block: 'center' }));
  }

  get shouldShowMissingInformationDisclaimer() {
    let { legalCountry } = this.model.organization;
    let {
      addressCity,
      addressZipCode,
      addressCountry,
      activityDescription,
      taxCountry,
      needsTaxNumber,
      taxIdentificationNumber,
      addressFirstLine,
      legalName,
      contactEmail,
      legalCode,
      legalSector,
      annualTurnover,
      estimatedAnnualTurnover,
      yearlyTransactionVolume,
      maxSinglePaymentAmount,
    } = this.model.organizationChangeRequest;
    let isAnnualTurnoverMissing = this.showEstimatedAnnualTurnoverField
      ? !estimatedAnnualTurnover
      : !annualTurnover;
    let isYearlyTransactionVolumeMissing = !yearlyTransactionVolume;
    let isMaxSinglePaymentAmountMissing = !maxSinglePaymentAmount;
    let isNatureOfOperationsMissing = !this.selectedNatureOfOpsOptions;

    if (legalCountry === 'DE') {
      isAnnualTurnoverMissing = false;
      isYearlyTransactionVolumeMissing = false;
      isMaxSinglePaymentAmountMissing = false;
      isNatureOfOperationsMissing = false;
    }

    return (
      !legalName ||
      !addressCity ||
      !addressFirstLine ||
      !addressZipCode ||
      !addressCountry ||
      !taxCountry ||
      (needsTaxNumber && !taxIdentificationNumber) ||
      !contactEmail ||
      !legalCode ||
      !legalSector ||
      isAnnualTurnoverMissing ||
      isYearlyTransactionVolumeMissing ||
      isMaxSinglePaymentAmountMissing ||
      isNatureOfOperationsMissing ||
      (this.isActivityDescriptionMandatory && !activityDescription)
    );
  }

  submitTask = dropTask(async () => {
    this.set('showValidations', true);

    let { legalCountry } = this.model.organization;
    let excludes = ['legalRegistrationDate', 'legalNumber'];

    if (legalCountry === 'DE') {
      excludes.push(
        'annualTurnover',
        'estimatedAnnualTurnover',
        'yearlyTransactionVolume',
        'maxSinglePaymentAmount',
        'natureOfOperations'
      );
    } else if (this.showEstimatedAnnualTurnoverField) {
      excludes.push('annualTurnover');
    } else {
      excludes.push('estimatedAnnualTurnover');
    }

    if (!this.isActivityDescriptionMandatory) {
      excludes.push('activityDescription');
    }

    let { validations } = await this.model.organizationChangeRequest.validate({ excludes });

    if (validations.isInvalid) {
      this.scrollToFirstErrorInput();
    }

    if (
      validations.isInvalid ||
      (this.model.organizationChangeRequest.needNewRegistrationCertificate &&
        !this.model.kbis.length)
    ) {
      if (!this.model.kbis.length) {
        this.set('showEmptyFileError', true);
      }

      return;
    }

    this.model.organizationChangeRequest.set('didValidate', true);

    try {
      if (
        this.model.organizationChangeRequest.needNewRegistrationCertificate ||
        this.model.updateProcess.get('isNew')
      ) {
        if (this.model.kbis.length) {
          this.model.updateProcess.kycKybUpdateKbisFile = this.model.kbis.at(-1);
        }
        await this.model.updateProcess.save();
      }

      await this.model.organizationChangeRequest.save();

      this.segment.track('kycb_update_save_organization');

      this.showValidations = false;
      this.model.organizationChangeRequest.set('didValidate', false);

      this.router.transitionTo('settings.company-profile.index');
      this.toastFlashMessages.toastSuccess(
        this.intl.t('organizations.profile.company-profile.success-toast.information-saved')
      );
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
    }
  });
}
