export default {
  "mandates": "qAP",
  "mandates-empty": "qAl",
  "header": "qAX",
  "header-empty": "qAC",
  "header-content": "qAk",
  "search": "qAJ",
  "search-bar": "qAs",
  "body": "qAF",
  "isEmpty": "qAg",
  "left-section": "qAD",
  "mandates-list": "qAV",
  "mandate-suspended": "qAL",
  "list-title": "qAT caption-bold",
  "list-empty": "qAz",
  "empty-illustration": "qAa",
  "empty-title": "qAH title-3",
  "empty-description": "qAO body-2",
  "body-details": "qAm",
  "l-app-content__page-header": "qAp",
  "pagination-footer": "qAx"
};
