import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

import { CFT } from 'qonto/constants/sentry-tags';

export default class UpcomingTransactionsRoute extends Route {
  @service abilities;
  @service homePage;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', CFT.CASH_FLOW_MANAGEMENT);

    if (this.abilities.cannot('view upcoming transactions cash-flow')) {
      return this.homePage.visitDefaultPage();
    }
  }
}
