export default {
  "container": "qfZ",
  "form-container": "qfR",
  "title": "qfQ",
  "title-border": "qfe",
  "tabs-container": "qfB",
  "preview-container": "qfE",
  "email-preview-container": "qfd",
  "close-button": "qfI btn btn--icon-only btn--tertiary btn--large"
};
