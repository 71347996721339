import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { restartableTask, timeout } from 'ember-concurrency';

import { APPROVAL_WORKFLOW_STATUSES } from 'qonto/constants/approval-workflow';
import { CARD_LEVELS } from 'qonto/constants/cards';
import { LAYOUT } from 'qonto/constants/empty-states/system';
import { getEmptyStateConfig, TABS } from 'qonto/constants/empty-states/tasks-and-requests';
import { REQUEST_TYPES, STATUS } from 'qonto/constants/requests';
import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { filterParams } from 'qonto/utils/compute-query-params';

const DEFAULT_SORT_BY = 'created_at:desc';

export default class TasksPastIndexController extends Controller {
  @service abilities;
  @service toastFlashMessages;
  @service intl;
  @service organizationManager;
  @service featuresManager;
  @service store;
  @service router;
  @service notifierCounterManager;
  @service emptyStates;

  @tracked highlight = '';
  @tracked page = 1;
  @tracked per_page = 25;
  @tracked requests = [];
  @tracked meta = null;
  @tracked sort_by = DEFAULT_SORT_BY;

  CARD_LEVELS = CARD_LEVELS;

  get localState() {
    let { isRunning, last } = this.fetchDataTask;
    let isEmpty = this.requests.length === 0;

    if (isRunning) {
      return {
        isLoading: true,
        error: false,
        empty: false,
      };
    }

    if (last.isError) {
      return {
        isLoading: false,
        error: true,
        empty: false,
      };
    }

    if (isEmpty) {
      return {
        isLoading: false,
        error: false,
        empty: true,
      };
    }
  }

  get isEmptyLocally() {
    return this.requests.length === 0;
  }

  get isEmptyGlobally() {
    let {
      totalDirectDebitCollectionRequests = 0,
      totalTransferRequests = 0,
      totalMileageRequests = 0,
      totalExpenseReportRequests = 0,
      totalCardRequests = 0,
    } = this.notifierCounterManager.counter || {};
    return (
      totalDirectDebitCollectionRequests +
        totalTransferRequests +
        totalMileageRequests +
        totalExpenseReportRequests +
        totalCardRequests ===
      0
    );
  }

  get isEmptyStatePreviewLayout() {
    return this.emptyStateRevampOptions?.layout === LAYOUT.DISCOVER_PREVIEW;
  }

  get isEmptyStateInformLayout() {
    return this.emptyStateRevampOptions?.layout === LAYOUT.INFORM;
  }

  get shouldShowApprovalWorkflowDiscoveryCard() {
    return !this.fetchDataTask.isRunning;
  }

  get emptyStateRevampOptions() {
    if (this.fetchDataTask.isRunning || this.fetchDataTask.last?.isError) {
      return;
    }

    let canUse = this.abilities.can('use request');
    let canReviewExpenseReportMileageTransferCardRequests =
      canUse &&
      (this.abilities.can('review card request') ||
        this.abilities.can('review transfer request') ||
        this.abilities.can('review expense-report request') ||
        this.abilities.can('review mileage request'));

    return this.emptyStates.getEmptyStateOptions({
      isOrgEligibleForFeature: true,
      isEmptyGlobally: this.isEmptyGlobally,
      isEmptyLocally: this.isEmptyLocally,
      hasActiveFilterOrSearch: false,
      config: getEmptyStateConfig(this.intl, 'tasks'),
      customInputs: {
        tab: TABS.COMPLETED,
      },
      abilities: {
        canReviewExpenseReportMileageTransferCardRequests,
        canReviewTransfers: this.abilities.can('review transfer request'),
      },
    });
  }

  get pendingCounter() {
    return this.notifierCounterManager.counter?.request === 0
      ? null
      : this.notifierCounterManager.counter?.request;
  }

  handleSelectRequestTask = restartableTask(async requestId => {
    let request = this.requests.find(({ id }) => id === requestId);

    if (request.requestType === REQUEST_TYPES.MULTI_TRANSFER) {
      this.router.transitionTo('tasks.past.multi-transfer-detail', request.id);
      return;
    }

    if (request.requestType === REQUEST_TYPES.MULTI_DIRECT_DEBIT_COLLECTION) {
      this.router.transitionTo('tasks.past.multi-direct-debit-collection', request.id);
      return;
    }

    if (request.requestType === REQUEST_TYPES.TRANSFER) {
      await request.hasMany('attachments').load();
    }

    await request.belongsTo('approvalWorkflowState').reload();

    this.highlight = request.id;
  });

  @action clearHighlight() {
    this.highlight = '';
  }

  @action
  handleSortBy(sortDefinition) {
    this.highlight = '';
    this.page = 1;
    this.sort_by = sortDefinition;
  }

  @action handlePerPageChange(value) {
    this.page = 1;
    this.per_page = value;
  }

  fetchDataTask = restartableTask(async (params = {}) => {
    await timeout(DEBOUNCE_MS);

    let { organization } = this.organizationManager;
    let { page, per_page, sort_by } = filterParams(params);

    let requestType = [
      ...(this.abilities.can('review transfer request')
        ? [REQUEST_TYPES.TRANSFER, REQUEST_TYPES.MULTI_TRANSFER]
        : []),
      ...(this.abilities.can('review card request')
        ? [REQUEST_TYPES.FLASH_CARD, REQUEST_TYPES.VIRTUAL_CARD]
        : []),
      ...(this.abilities.can('review expense report request')
        ? [REQUEST_TYPES.EXPENSE_REPORT]
        : []),
      ...(this.abilities.can('review mileage request') ? [REQUEST_TYPES.MILEAGE] : []),
      ...(this.abilities.can('review direct debit collection request')
        ? [REQUEST_TYPES.MULTI_DIRECT_DEBIT_COLLECTION]
        : []),
    ];

    let requestModel = {
      includes: ['memberships'],
      organization_id: organization.id,
      page,
      per_page,
      sort_by,
      status: [STATUS.DECLINED, STATUS.CANCELED, STATUS.APPROVED],
      request_type: requestType,
    };

    if (this.featuresManager.isEnabled('approvalWorkflows')) {
      requestModel.approval_workflow_status = APPROVAL_WORKFLOW_STATUSES.COMPLETED;
      requestModel.status = undefined;
    }

    let requests = await this.store.query('request', requestModel);

    this.requests = requests;
    this.meta = requests.meta;
  });

  resetQueryParams() {
    this.highlight = '';
    this.page = 1;
    this.per_page = 25;
    this.sort_by = DEFAULT_SORT_BY;
  }
}
