export default {
  "main-container": "qxC",
  "main": "qxk",
  "main-wrapper": "qxJ",
  "close-button": "qxs",
  "active-block": "qxF",
  "title": "qxg",
  "list-options": "qxD",
  "card": "qxV",
  "card-container": "qxL",
  "card-image": "qxT",
  "card-tiles": "qxz"
};
