export default {
  "signin": "qUK",
  "form": "qUG",
  "illustration": "qUr",
  "purple": "qUb",
  "mint": "qUy",
  "mustard": "qUU",
  "peach": "qUj",
  "form-wrapper": "qUW",
  "header-logo": "qUu",
  "links": "qjS",
  "no-account-label": "qjc",
  "spinner-container": "qjq",
  "main-image-container": "qjZ",
  "main-image": "qjR"
};
