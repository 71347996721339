export default {
  "mt-16": "qGf",
  "title": "qGK",
  "avatar": "qGG",
  "edit-organization-form": "qGr",
  "dropdown-content": "qGb",
  "city-block": "qGy",
  "zip-code-input": "qGU",
  "cancel-button": "qGj",
  "error-message": "qGW",
  "nature-of-operations": "qGu",
  "error": "qrS",
  "tax-country": "qrc",
  "tooltip": "qrq",
  "legal-code": "qrZ",
  "activity-type": "qrR"
};
