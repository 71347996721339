export default {
  "wrapper": "qbK",
  "integrations": "qbG",
  "description": "qbr body-2",
  "api": "qbb flex flex-column",
  "api-container": "qby flex large",
  "api-input": "qbU large",
  "api-btn": "qbj",
  "key-input": "qbW"
};
