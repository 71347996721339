export default {
  "container": "qbZ",
  "wrapper": "qbR",
  "grey-header-block": "qbQ",
  "connect-app-header": "qbe",
  "connect-app-header-link": "qbB",
  "connect-disclaimer-wrapper": "qbE",
  "connect-app-header-content": "qbd",
  "connect-app-header-cta": "qbI",
  "connect-app-content": "qbt",
  "connect-app-description": "qbM",
  "connect-app-body": "qbP"
};
