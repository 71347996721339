export default {
  "splash-landing": "qpW",
  "container": "qpu",
  "options": "qxS",
  "physical": "qxc",
  "card-label": "qxq",
  "physical-cta-container": "qxZ",
  "physical-cta": "qxR",
  "coming-soon": "qxQ"
};
