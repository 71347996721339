import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { SOURCE_TYPES } from 'qonto/constants/supplier-invoice';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class SupplierInvoicesIntroRoute extends Route {
  @service organizationManager;
  @service router;
  @service segment;
  @service store;
  @service sentry;
  @service supplierInvoicesManager;

  activate() {
    this.segment.track('empty-state_shown', {
      'empty-state_feature': 'supplier-invoices',
      'empty-state_type': 'activate',
    });
  }

  async model() {
    let isFirstTimeUsage = await this.supplierInvoicesManager.fetchFirstTimeUsageTask.perform();

    this.fetchAutomatedUploadedInvoicesTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });

    return {
      isFirstTimeUsage,
      fetchAutomatedUploadedInvoicesTask: this.fetchAutomatedUploadedInvoicesTask,
    };
  }

  afterModel({ isFirstTimeUsage }) {
    if (!variation('feature--boolean-improve-first-time-exp-si') || !isFirstTimeUsage) {
      return this.router.replaceWith('supplier-invoices.list');
    }
  }

  fetchAutomatedUploadedInvoicesTask = dropTask(async () => {
    return await this.store.query('supplier-invoice', {
      page: 1,
      per_page: 25,
      filter: {
        source: [
          SOURCE_TYPES.EMAIL,
          SOURCE_TYPES.PAY_BY_INVOICE,
          SOURCE_TYPES.E_INVOICING,
          SOURCE_TYPES.INTEGRATION,
        ],
      },
    });
  });
}
