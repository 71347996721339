export default {
  "page-container": "qiT",
  "form": "qiz",
  "close-button": "qia btn btn--icon-only btn--tertiary btn--large",
  "preview": "qiH",
  "preview-padding": "qiO",
  "title": "qim",
  "header": "qip",
  "without-tabs": "qix",
  "form-section": "qiw",
  "form-footer": "qiN",
  "disclaimer-text": "qiv"
};
