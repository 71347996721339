export default {
  "page": "qhH",
  "container": "qhO",
  "form-container": "qhm",
  "preview-container": "qhp",
  "pdf-preview-container": "qhx",
  "content": "qhw",
  "header": "qhN",
  "close-button": "qhv btn btn--icon-only btn--tertiary btn--large"
};
