export default {
  "page": "qKJ",
  "container": "qKs",
  "form-container": "qKF",
  "preview-container": "qKg",
  "pdf-preview-container": "qKD",
  "content": "qKV",
  "title": "qKL",
  "tabs-container": "qKT",
  "close-button": "qKz btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qKa",
  "header": "qKH",
  "without-tabs": "qKO"
};
