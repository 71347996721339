export default {
  "header": "qrQ",
  "content": "qre",
  "read-only": "qrB",
  "document-collection-banner": "qrE",
  "submit-banner": "qrd",
  "review-banner": "qrI",
  "disclaimer": "qrt",
  "title": "qrM",
  "card-container": "qrP",
  "representatives-section": "qrl",
  "disabled-href": "qrX"
};
