export default {
  "container": "qri",
  "link-grey": "qrA",
  "slack-section-title": "qrY title-4 mb-8",
  "edit-channel-text": "qrh body-2 mb-16",
  "rule-section-title": "qrf title-4 mb-16",
  "rule-cards-container": "qrK",
  "edit-slack-container": "qrG",
  "rule-card": "qrr",
  "add-rule-container": "qrb",
  "add-rule-link-button": "qry",
  "add-rule-text": "qrU",
  "empty-state": "qrj",
  "empty-state-textblock": "qrW",
  "empty-state-title": "qru title-2",
  "empty-state-description": "qbS body-2",
  "empty-state-illustration": "qbc",
  "rules": "qbq"
};
