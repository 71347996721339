export default {
  "page-wrapper": "qjQ",
  "content-wrapper": "qje",
  "content": "qjB",
  "period-field": "qjE",
  "format-options": "qjd",
  "format-option": "qjI",
  "format-radio": "qjt",
  "format-description": "qjM body-2"
};
