import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { reads } from 'macro-decorators';

import { CheckSidebar } from 'qonto/react/components/checks/check-sidebar';

export default class ChecksIndexController extends Controller {
  @service abilities;
  @service flowLinkManager;
  @service organizationManager;
  @service intl;
  @service router;

  queryParams = ['highlight', 'page', 'per_page', 'sort_by', { bankAccounts: 'bank-accounts' }];

  @tracked highlight = '';
  @tracked page = 1;
  @tracked per_page = 25;
  @tracked sort_by = 'created_at:desc';
  @tracked bankAccounts = '';

  get checks() {
    return this.model.fetchChecksTask.lastSuccessful?.value || [];
  }

  @reads('organizationManager.organization') organization;
  @reads('organizationManager.organization.hasExternalAccounts') hasExternalAccounts;

  get isChecksCreationEnabled() {
    return this.abilities.can('create check');
  }

  get selectedBankAccount() {
    let { accounts, currentAccount } = this.organizationManager;
    return accounts.find(({ id }) => id === this.bankAccounts) || currentAccount;
  }

  get organizationMaxCreditDelayDays() {
    return this.organization.checkSettings.max_credit_delay_days || 0;
  }

  get emptyStateOptions() {
    if (this.model.fetchChecksTask.isRunning || this.model.fetchChecksTask.last.isError) {
      return false;
    }

    let isEmpty = this.checks.length === 0;

    let button;

    if (this.isChecksCreationEnabled) {
      button = {
        label: this.intl.t('empty-states.checks.admin.button'),
        callback: () => {
          this.flowLinkManager.transitionTo({
            name: 'check',
            stepId: 'intro',
          });
        },
      };
    }

    if (isEmpty) {
      if (this.selectedBankAccount?.isClosed) {
        button = this.organization.hasMultipleActiveAccounts
          ? {
              label: this.intl.t('empty-states.shared.closed-account.see-all-accounts.button'),
              callback: () => {
                this.router.transitionTo('checks', { queryParams: { 'bank-accounts': '' } });
              },
            }
          : null;

        return {
          title: this.intl.t('empty-states.checks.closed-account.title'),
          subtitle: this.intl.t('empty-states.checks.closed-account.subtitle'),
          lottieSrc: '/lotties/check/check.json',
          button,
        };
      } else if (!this.isChecksCreationEnabled) {
        return {
          title: this.intl.t('empty-states.checks.creation-disabled.title'),
          subtitle: this.intl.t('empty-states.checks.creation-disabled.subtitle'),
          lottieSrc: '/lotties/check/check.json',
          button,
        };
      } else {
        return {
          title: this.intl.t('empty-states.checks.admin.title'),
          subtitle: this.intl.t('empty-states.checks.admin.subtitle'),
          lottieSrc: '/lotties/check/check.json',
          button,
        };
      }
    }

    return false;
  }

  get listEmptyState() {
    if (this.model.fetchChecksTask.isRunning) {
      return {
        legendKey: '',
        illuPath: '',
        isActive: false,
        isLoading: true,
      };
    }

    if (this.model.fetchChecksTask.last.isError) {
      return {
        illuPath: '/illustrations/qonto-empty-result.svg',
        legendKey: this.intl.t('empty-states.list_error'),
        isActive: true,
        isLoading: false,
      };
    }

    return {
      legendKey: '',
      illuPath: '',
      isActive: false,
      isLoading: false,
    };
  }

  get hasValidMandate() {
    return this.model.fetchMandateTask.lastSuccessful?.value;
  }

  @action
  handlePerPageChange(value) {
    this.page = 1;
    this.per_page = value;
  }

  @action
  handleSortBy(sortDefinition) {
    this.highlight = '';
    this.page = 1;
    this.sort_by = sortDefinition;
  }

  @action
  updateHighlightedItem(itemId) {
    this.highlight = itemId;
  }

  get closeSidebarId() {
    return 'close-sidebar';
  }

  CheckSidebar = CheckSidebar;
}
