import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';

import { apiBaseURL, supplierInvoiceNamespace } from 'qonto/constants/hosts';
import { PREVIEW_TYPES } from 'qonto/constants/supplier-invoice';
import { ErrorInfo } from 'qonto/utils/error-info';
import transformKeys from 'qonto/utils/transform-keys';

export default class SupplierInvoicesCreditNotesPreviewRoute extends Route {
  @service intl;
  @service networkManager;
  @service store;
  @service toastFlashMessages;
  @service sentry;
  @service modals;

  queryParams = {
    type: { refreshModel: true },
  };

  async model(params) {
    let { type = PREVIEW_TYPES.SUGGESTED, invoice_id: invoiceId } = params;
    let fetchTask;

    try {
      if (type === PREVIEW_TYPES.SUGGESTED) {
        fetchTask = this.fetchSuggestedCreditNotesTask;
        await this.fetchSuggestedCreditNotesTask.perform(invoiceId);
      } else if (type === PREVIEW_TYPES.RELATED) {
        fetchTask = this.fetchInvoiceWithRelatedCreditNotesTask;
        await this.fetchInvoiceWithRelatedCreditNotesTask.perform(invoiceId);
      }

      this.modal = this.modals.open('supplier-invoices/credit-notes-preview-modal', {
        linkToInvoiceTask: this.linkToInvoiceTask,
        fetchTask,
        isFullScreenModal: true,
        invoiceId,
        type,
      });
    } catch (error) {
      this._handleError(error);
    }
  }

  deactivate() {
    this.modal?.close();
  }

  fetchSuggestedCreditNotesTask = dropTask(async invoiceId => {
    let response = await this.networkManager.request(
      `${apiBaseURL}/${supplierInvoiceNamespace}/supplier_invoices/${invoiceId}/suggested_credit_notes`
    );

    let creditNotesWithAttachments = await Promise.all(
      response.credit_notes.map(async creditNote => {
        try {
          let attachment = null;
          if (creditNote.attachment_id) {
            attachment = await this.store.findRecord('attachment', creditNote.attachment_id);
          }

          let transformedCreditNote = transformKeys(creditNote);

          return {
            ...transformedCreditNote,
            attachment,
          };
        } catch {
          let transformedCreditNote = transformKeys(creditNote);
          return {
            ...transformedCreditNote,
            attachment: null,
          };
        }
      })
    );

    return creditNotesWithAttachments;
  });

  fetchInvoiceWithRelatedCreditNotesTask = dropTask(async invoiceId => {
    let response = await this.store.findRecord('supplier-invoice', invoiceId);

    let invoicesWithAttachments = await Promise.all(
      response.relatedInvoices.map(async relatedInvoice => {
        try {
          let attachment = null;
          if (relatedInvoice.attachment_id) {
            attachment = await this.store.findRecord('attachment', relatedInvoice.attachment_id);
          }

          let transformedInvoice = transformKeys(relatedInvoice);

          return {
            ...transformedInvoice,
            isCreditNote: true,
            supplierSnapshot: response.supplierSnapshot,
            status: response.status,
            iban: response.iban,
            issueDate: response.issueDate,
            dueDate: response.dueDate,
            attachment,
          };
        } catch {
          let transformedInvoice = transformKeys(relatedInvoice);
          return {
            ...transformedInvoice,
            isCreditNote: true,
            supplierSnapshot: response.supplierSnapshot,
            status: response.status,
            iban: response.iban,
            issueDate: response.issueDate,
            dueDate: response.dueDate,
            attachment: null,
          };
        }
      })
    );

    let serializedInvoice = transformKeys(response.serialize());

    return {
      ...serializedInvoice,
      id: response.id,
      relatedInvoices: invoicesWithAttachments,
    };
  });

  linkToInvoiceTask = dropTask(async (invoiceId, creditNoteId) => {
    try {
      return await this.networkManager.request(
        `${apiBaseURL}/${supplierInvoiceNamespace}/supplier_invoices/${invoiceId}/link_credit_note`,
        { method: 'POST', data: { credit_note_id: creditNoteId } }
      );
    } catch (error) {
      this._handleError(error);
    }
  });

  _handleError(error) {
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
    if (ErrorInfo.for(error).shouldSendToSentry) {
      this.sentry.captureException(error);
    }
  }
}
