import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { next } from '@ember/runloop';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import scrollIntoView from 'qonto/utils/scroll-into-view';

export default class InvoicingSettingsRoute extends Route {
  @service abilities;
  @service homePage;
  @service intl;
  @service organizationManager;
  @service router;
  @service store;
  @service sentry;
  @service subscriptionManager;
  @service toastFlashMessages;

  queryParams = {
    //Used to scroll to a specific section of the form
    target: {
      refreshModel: false,
    },
  };

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'invoices');

    let canNavigate = this.abilities.can('navigate receivable-invoice');

    if (!canNavigate) {
      return this.homePage.replaceWithDefaultPage();
    }
  }

  async model() {
    let { organization } = this.organizationManager;

    let settings;

    try {
      settings = await this.store.findRecord('receivable-invoices-settings', organization.id);
    } catch (error) {
      this.handleError(error);
      this._redirectOnError();
    }

    try {
      await organization.getAvatar();
    } catch (error) {
      this.handleError(error);
    }

    if (settings) {
      settings.contactEmail = settings.contactEmail || organization.membership?.email;
      organization.vatNumber = settings.vatNumber;
      organization.taxNumber = settings.taxNumber;
      organization.companyLeadership = settings.companyLeadership;
      organization.districtCourt = settings.districtCourt;
      organization.commercialRegisterNumber = settings.commercialRegisterNumber;
    }

    this.fetchInvoiceSubscriptionsStatsTask
      .perform()
      .catch(ignoreCancelation)
      .finally(() => {
        if (variation('feature-invoices-ar-onboarding')) {
          this.scrollToTargetedAnchor();
        }
      });

    return {
      settings,
      fetchInvoiceSubscriptionsStatsTask: this.fetchInvoiceSubscriptionsStatsTask,
    };
  }

  fetchInvoiceSubscriptionsStatsTask = dropTask(async () => {
    // @ts-expect-error
    return await this.store.adapterFor('invoice-subscription').getStats();
  });

  /**
   * Scroll to the targeted anchor based on passed query param
   */
  @action
  scrollToTargetedAnchor() {
    let { target } = this.router.currentRoute.queryParams;
    if (target) {
      next(() => {
        scrollIntoView(document.getElementById(target), { offsetTop: 32 });
      });
    }
  }

  handleError(error) {
    if (!error.isAdapterError) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  }

  _redirectOnError() {
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    return this.router.transitionTo('receivable-invoices');
  }
}
