import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { ErrorState } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { FRENCH_EINVOICING_RECEIVE_INVOICES_STATUS } from 'qonto/constants/french-einvoicing-settings';
import {
  FrenchEinvoicingStatus,
  FrenchEInvoicingStatusLoading,
} from 'qonto/react/components/settings/einvoicing';
import { getEinvoicingFrSettingsStatusTag } from 'qonto/utils/einvoicing-fr-settings';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class SettingsEinvoicingController extends Controller {
  FrenchEinvoicingStatus = FrenchEinvoicingStatus;
  frenchEInvoicingStatusLoading = FrenchEInvoicingStatusLoading;
  ErrorState = ErrorState;

  @service abilities;
  @service intl;
  @service organizationManager;
  @service localeManager;
  @service modals;
  @service segment;
  @service zendeskWidget;
  @service toastFlashMessages;
  @service sentry;

  get canSeeDisableEinvoicingCta() {
    return this.abilities.can('write einvoicingSetting');
  }

  get einvoicingFrSettings() {
    return this.model?.settingsTask?.lastSuccessful?.value || {};
  }

  get sendStatus() {
    return this.einvoicingFrSettings?.einvoicingSendingEnabled;
  }

  get receiveStatus() {
    return this.einvoicingFrSettings?.einvoicingReceivingStatus;
  }

  get sendStatusDisplayed() {
    let status = this.sendStatus
      ? FRENCH_EINVOICING_RECEIVE_INVOICES_STATUS.enabled
      : FRENCH_EINVOICING_RECEIVE_INVOICES_STATUS.disabled;

    return getEinvoicingFrSettingsStatusTag(this.intl, status);
  }

  get receiveStatusDisplayed() {
    let status = this.sendStatus
      ? this.receiveStatus
      : FRENCH_EINVOICING_RECEIVE_INVOICES_STATUS.disabled;

    return getEinvoicingFrSettingsStatusTag(this.intl, status);
  }

  get receiveStatusEnabled() {
    return this.receiveStatus === FRENCH_EINVOICING_RECEIVE_INVOICES_STATUS.enabled;
  }

  get receiveStatusDisabled() {
    return this.receiveStatus === FRENCH_EINVOICING_RECEIVE_INVOICES_STATUS.disabled;
  }

  get receiveStatusNoQonto() {
    return this.receiveStatus === FRENCH_EINVOICING_RECEIVE_INVOICES_STATUS.no_qonto;
  }

  get receiveStatusPendingCreation() {
    return this.receiveStatus === FRENCH_EINVOICING_RECEIVE_INVOICES_STATUS.pending_creation;
  }

  get receiveStatusPendingDeletion() {
    return this.receiveStatus === FRENCH_EINVOICING_RECEIVE_INVOICES_STATUS.pending_deletion;
  }

  get pdpAddressesArray() {
    return this.einvoicingFrSettings?.addresses;
  }

  get mainPDPAddress() {
    if (!this.receiveStatusEnabled || !this.sendStatus) return null;

    return this.pdpAddressesArray?.find(address => address?.is_qonto)?.address;
  }

  get mainNoQontoPdpName() {
    let hasManyAddress = this.pdpAddressesArray?.length > 1;
    let mainPdpName = this.pdpAddressesArray
      ? hasManyAddress
        ? this.pdpAddressesArray?.find(
            address => !address?.is_qonto && address?.address?.length === 9
          )?.pdp_name
        : this.einvoicingFrSettings?.addresses[0]?.pdp_name
      : undefined;

    return mainPdpName;
  }

  get receiveStatusSubtitle() {
    let { organization } = this.organizationManager;
    let slug = organization?.slug;
    let supplierInvoicesRoute = `/organizations/${slug}/supplier-invoices`;

    if (!this.sendStatus) {
      return this.intl.t('settings.einvoicing.receive-status.disabled-subtitle.body.text', {
        supplierInvoices: supplierInvoicesRoute,
      });
    } else if (this.receiveStatusEnabled) {
      return this.intl.t('settings.einvoicing.receive-status.subtitle.body.text', {
        supplierInvoices: supplierInvoicesRoute,
      });
    } else if (this.receiveStatusPendingCreation) {
      return this.intl.t('settings.einvoicing.receive-status.enable-in-progress-subtitle');
    } else if (this.receiveStatusNoQonto) {
      return this.intl.t('settings.einvoicing.receive-status.not-qonto-subtitle', {
        pdpName: this.mainNoQontoPdpName,
      });
    } else if (this.receiveStatusDisabled) {
      return this.intl.t('settings.einvoicing.receive-status.disabled-subtitle.body.text', {
        supplierInvoices: supplierInvoicesRoute,
      });
    } else if (this.receiveStatusPendingDeletion) {
      return this.intl.t('settings.einvoicing.receive-status.disable-in-progress-subtitle');
    }
  }

  get receiveStatusCta() {
    let type = 'primary';

    if (!this.canSeeDisableEinvoicingCta || !this.sendStatus) {
      return null;
    }

    if (this.receiveStatusNoQonto) {
      return {
        text: this.intl.t('settings.einvoicing.receive-status.cta.contact'),
        type,
        action: this.openZendeskChat,
      };
    } else if (this.receiveStatusDisabled) {
      return {
        text: this.intl.t('settings.einvoicing.send-status.cta.enable'),
        type,
        action: this.enableReceptionTask.perform,
        isLoading: this.enableReceptionTask.isRunning,
      };
    } else if (this.receiveStatusEnabled) {
      return {
        text: this.intl.t('settings.einvoicing.send-status.cta.disable'),
        type: 'tertiary',
        action: this.startDeactivationFlow,
      };
    }
  }

  @action openZendeskChat() {
    this.zendeskWidget.open();

    this.segment.track('e-invoicing-settings_contact-CTA_clicked');
  }

  @action startDeactivationFlow() {
    this.segment.track('e-invoicing-settings_deactivate-CTA_clicked');

    this.modals.open(
      'settings/einvoicing/refusal-modal',
      {
        einvoicingSettings: this.einvoicingFrSettings,
        isFullScreenModal: true,
        address: this.mainPDPAddress,
      },
      {
        focusTrapOptions: {
          clickOutsideDeactivates: false,
          allowOutsideClick: true,
        },
      }
    );
  }

  enableReceptionTask = dropTask(async () => {
    try {
      let settings = this.model.settingsTask.lastSuccessful.value;

      await settings.activate();

      this.segment.track('e-invoicing-settings_activate-CTA_clicked');

      this.toastFlashMessages.toastSuccess(
        this.intl.t('settings.einvoicing.receive-status.enable.success-toast')
      );
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  get generateTypeformUrl() {
    let currentLocale = this.localeManager.locale;
    let { organization } = this.organizationManager;
    let orgId = organization?.id;
    let orgLegalNumber = organization?.legalNumber;
    let orgName = organization?.name;

    let typeFormBaseUrl = `https://qonto.typeform.com`;
    let typeFormUrlParams = `orga_id=${orgId}&legal_number=${orgLegalNumber}&name=${orgName}`;
    let typeFormUrl;

    if (currentLocale === 'fr') {
      typeFormUrl = `${typeFormBaseUrl}/e-facturation#${typeFormUrlParams}`;
    } else {
      typeFormUrl = `${typeFormBaseUrl}/e-invoicing#${typeFormUrlParams}`;
    }

    return typeFormUrl;
  }
}
