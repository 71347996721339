export default {
  "details": "qiU",
  "header": "qij",
  "avatar": "qiW mr-16",
  "header-beneficiary": "qiu",
  "header-beneficiary-activity": "qAS",
  "header-beneficiary-details": "qAc",
  "header-beneficiary-details-recurring": "qAq",
  "infos": "qAZ",
  "infos-title": "qAR",
  "infos-empty": "qAQ",
  "infos-list": "qAe",
  "infos-list-item": "qAB",
  "infos-list-item-left": "qAE",
  "infos-list-item-right": "qAd",
  "infos-list-item-attachment": "qAI",
  "infos-transactions": "qAt",
  "actions": "qAM"
};
