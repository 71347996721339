export default {
  "team": "qAr",
  "no-members": "qAb",
  "teams-header": "qAy",
  "header-search": "qAU",
  "search-bar": "qAj",
  "invite": "qAW body-2",
  "invite-text": "qAu",
  "empty-illustration": "qYS",
  "empty-title": "qYc title-3",
  "empty-description": "qYq body-2",
  "pagination-footer": "qYZ",
  "filter-search": "qYR",
  "fullsize": "qYQ",
  "tabs-container": "qYe",
  "tabs-separator": "qYB",
  "tooltip-wrapper": "qYE",
  "invite-cta": "qYd",
  "kyc-disclaimer": "qYI",
  "kyc-disclaimer-width": "qYt"
};
