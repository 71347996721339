export default {
  "page-container": "qid",
  "form": "qiI",
  "close-button": "qit btn btn--icon-only btn--tertiary btn--large",
  "preview": "qiM",
  "preview-padding": "qiP",
  "title": "qil",
  "header": "qiX",
  "without-tabs": "qiC",
  "form-section": "qik",
  "form-footer": "qiJ",
  "disclaimer-text": "qis"
};
