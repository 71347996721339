import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { cached, tracked } from '@glimmer/tracking';

import { EmptyStatesInform } from '@repo/domain-kit/pricing';
import { restartableTask } from 'ember-concurrency';
import { task as trackedTask } from 'ember-resources/util/ember-concurrency';

import { PAYMENT_LINK_STATUS } from 'qonto/constants/payment-links';
import { TrackRender } from 'qonto/react/components/track-render';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class PaymentLinksIndexController extends Controller {
  @service intl;
  @service store;
  @service sentry;
  @service toastFlashMessages;

  @tracked status = null;
  @tracked page = 1;
  @tracked perPage = 25;
  @tracked sortBy = 'expiration_date';
  @tracked highlight = null;

  emptyStatesInform = EmptyStatesInform;
  trackRender = TrackRender;

  get currentParams() {
    return {
      status: this.status,
      page: this.page,
      perPage: this.perPage,
      sortBy: this.sortBy,
    };
  }

  get paymentLinks() {
    return this.model.fetchPaymentLinksTask.lastSuccessful?.value.paymentLinks;
  }

  get meta() {
    return this.model.fetchPaymentLinksTask.lastSuccessful?.value.meta;
  }

  get isLoading() {
    return this.model.fetchPaymentLinksTask.isRunning;
  }

  get hasActiveFilters() {
    return Boolean(this.status);
  }

  get hasNoPaymentLinks() {
    return !this.hasActiveFilters && this.paymentLinks?.length === 0;
  }

  get hasNoResult() {
    return this.hasActiveFilters && this.paymentLinks?.length === 0;
  }

  get hasError() {
    return this.model.fetchPaymentLinksTask.last.isError;
  }

  get filterValue() {
    if (this.status?.split(',').length > 1) {
      return;
    }

    return this.filterOptions.filter(element => element.code === this.status)[0];
  }

  @cached
  get filterOptions() {
    return [
      {
        code: PAYMENT_LINK_STATUS.OPEN,
        label: this.intl.t('payment-links.payment-link-status.active'),
      },
      {
        code: PAYMENT_LINK_STATUS.PAID,
        label: this.intl.t('payment-links.payment-link-status.completed'),
      },
      {
        code: PAYMENT_LINK_STATUS.CANCELED,
        label: this.intl.t('payment-links.payment-link-status.cancelled'),
      },
      {
        code: PAYMENT_LINK_STATUS.EXPIRED,
        label: this.intl.t('payment-links.payment-link-status.expired'),
      },
    ];
  }

  get highlightableItems() {
    let { highlight } = this;
    if (highlight) {
      let paymentLink = this.store.peekRecord('payment-link', highlight);
      if (paymentLink && !this.paymentLinks.includes(paymentLink)) {
        return [...this.paymentLinks, paymentLink];
      }
    }
    return this.paymentLinks;
  }

  loadHighlightedResourceTask = restartableTask(async highlight => {
    if (!highlight) {
      return null;
    }
    if (this._highlightedPaymentLinkId === highlight) {
      return this._highlightedResource;
    }

    let paymentLink = this.store.peekRecord('payment-link', highlight);
    let invoiceId = paymentLink?.paymentInitiator.resourceId;

    if (!invoiceId) {
      return null;
    }

    this._highlightedPaymentLinkId = highlight;
    this._highlightedResource = null;

    try {
      this._highlightedResource = await this.store.findRecord('receivable-invoice', invoiceId);
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      this.toastFlashMessages.toastError(
        this.intl.t('payment-links.errors.related-documents-error')
      );
    }
    return this._highlightedResource;
  });

  highlightedResourceTask = trackedTask(this, this.loadHighlightedResourceTask, () => [
    this.highlight,
  ]);

  get highlightedResource() {
    return this.highlightedResourceTask.value || {};
  }

  get highlightedResourceFailed() {
    return !this.highlightedResourceTask.isRunning && !this.highlightedResourceTask.value;
  }

  @action
  updateFilter(selected) {
    if (!selected) {
      this.status = null;
      return;
    }
    this.status = selected.code;
    this.page = 1;
  }

  @action
  changePage(page) {
    this.page = page;
  }

  @action
  changePerPage(perPage) {
    this.perPage = perPage;
    this.page = 1;
  }

  @action
  handleSortBy(sortDefinition) {
    this.sortBy = sortDefinition;
    this.page = 1;
  }

  @action
  updateHighlightedItem(itemId) {
    this.highlight = itemId;
  }

  @action
  resetQueryParams() {
    this.page = 1;
    this.perPage = 25;
    this.status = null;
    this.sortBy = 'expiration_date';
    this.highlight = null;
  }
}
