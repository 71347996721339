import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { EmptyStatesInform } from '@repo/domain-kit/pricing';
import { restartableTask } from 'ember-concurrency';
import window from 'ember-window-mock';

import { DEFAULT_SORT_ORDER, RIBA_SORT_BYS } from 'qonto/constants/sort';
import { Sidebar } from 'qonto/react/components/riba/sidebar';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class RibaTasksController extends Controller {
  emptyStatesInform = EmptyStatesInform;

  @service intl;
  @service organizationManager;
  @service store;
  @service zendeskLocalization;

  @tracked highlight = null;
  @tracked page = 1;
  @tracked perPage = 25;
  @tracked sortBy = `${RIBA_SORT_BYS.EXECUTION_DATE}:${DEFAULT_SORT_ORDER}`;
  @tracked selectedPayments = [];
  @tracked sidebarPayment = null;

  queryParams = ['page', { perPage: 'per_page' }, { sortBy: 'sort_by' }];

  Sidebar = Sidebar;

  @action closeSidebar() {
    this.sidebarPayment = null;
  }

  @action openSidebar(payment) {
    this.sidebarPayment = payment;
  }

  @action updateParam(param, value) {
    this[param] = value;
  }

  get payments() {
    return this.fetchDataTask.last?.value || [];
  }

  get isLoading() {
    return this.fetchDataTask.isRunning;
  }

  get isError() {
    return this.fetchDataTask.last?.isError;
  }

  get isEmptyState() {
    return this.payments.length === 0 && !this.isLoading && !this.isError;
  }

  get hasNoActiveAccounts() {
    return !this.organizationManager.organization.activeAccounts.length;
  }

  @action onFaqClick() {
    let link = this.intl.t('aeat.empty-state.secondary-cta-url', {
      faqUrl: this.zendeskLocalization.getLocalizedArticle(3258378),
    });
    window.open(link, '_blank');
  }

  fetchDataTask = restartableTask(async () => {
    return await this.store.query('riba-payment', {
      statuses: ['pending'],
      page: this.page,
      per_page: this.perPage,
      sort_by: this.sortBy,
    });
  });

  @action
  handlePerPageChange(value) {
    this.page = 1;
    this.perPage = value;
  }

  @action
  changeSortBy(value) {
    this.sortBy = value;
    this.page = 1;
    this.reloadData();
  }

  @action
  reloadData() {
    this.fetchDataTask.perform().catch(ignoreCancelation);
  }

  get areAllSelected() {
    if (!this.payments?.length) return false;
    return this.selectedPayments.length === this.payments.length;
  }

  @action
  selectAll(isChecked) {
    if (!isChecked) {
      this.selectedPayments = [];
      return;
    }

    if (!this.payments?.length) return;
    this.selectedPayments = this.payments.map(payment => payment.id);
  }

  @action
  selectPayment(paymentId, isChecked) {
    if (isChecked) {
      if (!this.selectedPayments.includes(paymentId)) {
        this.selectedPayments = [...this.selectedPayments, paymentId];
      }
    } else {
      this.selectedPayments = this.selectedPayments.filter(id => id !== paymentId);
    }
  }
}
